<template>
    <h2>
        🤖 Página não encontrada!
    </h2>
</template>

<script>
    export default {
        name:"PageNotFound"
    }
</script>

<style scoped>
    h2{
        display: block;
        text-align: center;
        margin: 100px 0px;
        color: #3d3d3d;
    }
</style>